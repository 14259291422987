import React from "react";
//import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import "../index.css";
import "./events.css";
//import EventsNotice from "./EventsNotice";
//import { HeatmapLayerF } from "@react-google-maps/api";
//import '../multiCarousel/multiCar.css';

function Events() {
  return (
    <div className="i-top">
      <div className="ffdRow">
        <div className="eventCol">
          <img
            className="eventImg"
            style={{ marginBottom: "0px" }}
            src={process.env.PUBLIC_URL + "/events/" + "ffdposter.png"}
            alt="2024FFDevent"
          ></img>
        </div>
      </div>
      <div className="ffdRow">
        <div className="ffdCol">
          <div className="eventStyle1">
            <img
              className="eventImg"
              style={{ marginBottom: "0px" }}
              src={
                process.env.PUBLIC_URL + "/events/" + "20230721photocntst1.PNG"
              }
              alt="photo contest"
            ></img>
          </div>
          <div>
            <p className="h2Format">COMPETITION WINNER</p>
          </div>
          <div className="photoTitle">Bridge</div>
          <div className="photographer">by Tony Leung</div>
          <div>
            <img
              className="eventImg"
              style={{ marginBottom: "0px" }}
              src={process.env.PUBLIC_URL + "/events/" + "winnerBridge.JPG"}
              alt="photo contest"
            ></img>
          </div>
          <div>
            <h2 className="h2Format">RUNNER UP</h2>
          </div>
          <div className="photoTitle">Flower Garden</div>
          <div className="photographer">by David England</div>
          <div>
            <img
              className="eventImg"
              style={{ marginBottom: "0px" }}
              src={process.env.PUBLIC_URL + "/events/" + "2ndDreams.jpg"}
              alt="photo contest"
            ></img>
          </div>
          <div className="eventStyle1" style={{ marginTop: "0px" }}>
            <img
              className="eventImg"
              style={{ marginTop: "0px" }}
              src={process.env.PUBLIC_URL + "/events/" + "202307212.PNG"}
              alt="photo contest"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
