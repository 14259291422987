//EventMC2:Wedding promotion for Gazebo area
import React from "react";
import { useNavigate } from "react-router-dom";

/*import { BsDiagram2Fill } from "react-icons/bs";*/
/*import {useHistory} from "react-router-dom";*/
import NwsltrCntrlr from "../newsletter/NwsltrCntrlr";
//import '../multiCarousel/multiCar.css';


function EventMC2 () {
  const navigate = useNavigate();

  return (
    <div className="mc3bkgnd">
      <div className="eventsHdrFlex">
        <div className="eventsGazebo">
          <p>We offer our beautiful Gazebo area for wedding ceremonies and other special gatherings</p>
        </div>
        <div className="eventsGoContact"
          onClick = {()=>navigate('/support')}>
          <p>CLICK HERE TO CONTACT US FOR MORE INFORMATION</p>
        </div>


      </div>
      <div className="sbscrHdrFlxMC">
        <NwsltrCntrlr />
      </div>

    </div>

  );
}

export default EventMC2;
