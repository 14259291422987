/*Note: this is MCarouselMain, not Home.js*/
import React, { useState } from "react";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
//import './index.css';
//import './home.css';
import '../multiCarousel/multiCar.css';
import MCHome1 from "../multiCarousel/MCHome1.js";
import MCHome2 from "../events/EventMC2.js";
/*import MCHome3 from "../multiCarousel/MCHome3.js";*/
import MCHome4 from "../multiCarousel/MCHome4.js";
import MCHomeMap from "./MCHomeMap.js";



function Home () {
  const switchArr = ['MCHome1', 'MCHome4', 'MCHome2', 'MCHomeMap'];
  //const [currIndex, setIndex] = useState(0);
  const [currCase,setCurrCase] = useState('MCHome1');

  function nextItem ()  {
    console.log('entered nextItem: ' + currCase);
    let index = switchArr.indexOf(currCase);
    console.log(index);
    index=index + 1;
    let indexMax;
    indexMax=switchArr.length;
    if (index === indexMax) {
      index = 0;
    }
    setCurrCase(switchArr[index]);
    console.log('next update: ' + currCase);
  }
  function prevItem ()  {
    console.log('entered prevItem:' + currCase);
    let index = switchArr.indexOf(currCase);
    console.log(index);
    index=index - 1;
    if (index < 0) {
      index = (switchArr.length - 1);
    }
    setCurrCase(switchArr[index]);
    console.log('update: ' + currCase);
  }

  return (
    <div className="">
      <div><BsChevronLeft className='left-arrow' onClick={prevItem} />
        <BsChevronRight className='right-arrow' onClick={nextItem} />
      </div>

      <div className="">
        {(() => {
          switch (currCase) {
          case 'MCHome1':
            return <MCHome1 />;
          case 'MCHome2':
            return <MCHome2 />;
          case 'MCHome4':
            return <MCHome4 />;
          case 'MCHomeMap':
            return <MCHomeMap />;
          default:
            return null;
          }
        })()}
      </div>

    </div>
  );
}

export default Home;