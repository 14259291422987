import React, { useState } from "react";
import ExploreMenu from "./ExploreMenu";
import ExpFarmhouse from "./ExpFarmhouse";
import ExpHistory from "./ExpHistory";
import ExpChickens from "./ExpChickens";
import ExpPond from "./ExpPond";
import ExpHeritageGardens from "./ExpHeritageGardens";
import ExpAllotments from "./ExpAllotments";
import "./explore.css";

function Explore () {
  const [cntrlExp, setCntrlExp] = useState("History");

  function chgComponent  (cmpnt)  {
    setCntrlExp(cmpnt);
    console.log(cntrlExp);

  }
  /*<div className="expMenu">
					<div className="expMenuItems">*/
  return (
    <div className="i-top">
      <div className="expFlexCntnr">
        <div className="expMenu">
          <ExploreMenu chgComponent={chgComponent}/>
        </div>

        <div className="expArticle">
          {(() => {
            switch (cntrlExp) {

            case 'FarmHouse':
              return <ExpFarmhouse />;
            case 'History':
              return <ExpHistory />;
            case 'HeritageGardens':
              return <ExpHeritageGardens />;
            case 'Chickens':
              return <ExpChickens />;
            case 'Pond':
              return <ExpPond />;
            case 'Allotments':
              return <ExpAllotments />;
            default:
              return null;
            }
          })()}
        </div>
      </div>
    </div>
  );

}

export default Explore;