import React from "react";
import "../index.css";
import "../multiCarousel/multiCar.css";
import "../multiCarousel/ModalWinner";

function ModalWinner({ setOpenModal }) {
  //const [modalOpen, setModalOpen] = useState(false);
  /*<div className="eventStyle1" style={{ marginTop: "0px" }}>
          <img
            className="eventImg"
            style={{ marginTop: "0px" }}
            src={process.env.PUBLIC_URL + "/events/" + "202307212.PNG"}
            alt="photo contest"
          ></img>
        </div>*/
  return (
    <div className="modCntnr i-top">
      <div className="modal1">
        <div>
          <button
            className="modCloseBtn"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div>
          <p className="h2Home">LONDON FARM IS HIRING A BAKER!</p>
          <p className="modPara">
            London Farm is famous for our teas and other events that offer
            awesome baked goods to our customers. We are looking for a talented
            baker to join our team.
          </p>
        </div>
        <div>
          <img
            className="eventImg"
            style={{ marginBottom: "0px" }}
            src={process.env.PUBLIC_URL + "/events/" + "2024apriljobad.PNG"}
            alt="Job Ad - Baker"
          ></img>
        </div>
      </div>
    </div>
  );
}
export default ModalWinner;
