import React from "react";
import "./newsletter.css";

function NwsLtrSbscrDone(props) {
/*<div className="nwsLtrDoneCntnr">*/
  console.log(props.endMsg);
  return (
    <div className="nwsLtrDoneCntnr">
      <div className="nwsltrThanks">
        <p>{props.endMsg}</p>
      </div>
    </div>
  );
}
export default NwsLtrSbscrDone;