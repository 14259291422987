import React from "react";
//import { Link } from 'react-router-dom';
//import logoImg from '../img/logo.png';
//import { Card, Logo, Form, Input, Button } from '../AuthForm';

function Signup() {
  /*<Card>
			<Logo src={logoImg} />
			<Form>
				<Input type="email" placeholder="email" />
				<Input type="password" placeholder="password" />
				<Input type="password" placeholder="password again" />
				<Button>Sign Up</Button>
			</Form>
			<Link to="/login">Already have an account?</Link>
	</Card> */
  return (
    <div>
      <h1>THIS COMPONENT IS COMMENTED OUT</h1>
    </div>
  );
}

export default Signup;