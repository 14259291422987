//app.js version 1.01
import React, { useState } from "react";
import { BrowserRouter, NavLink, Routes, Route } from "react-router-dom";
import { BsCartFill } from "react-icons/bs";
//import BsCart from "./BsCart.js";
import logo from "./img/London_Farms_logo_4C.png";
import Home from "./home/Home.js";
import "./navbar.css";
import Explore from "./explore/Explore";
import Events from "./events/Events";
import StoreCntnr from "./store/StoreCntnr";
//import Login from "./login/Login";
import Gallery from "./gallery/Gallery";
import Support from "./support/Support";
import SignUp from "./login/SignUp";
import Weddings from "./weddings/Weddings";
import Donations from "./donations/Donations";
import JoinUs from "./joinus/JoinUs";
import ShoppingCart from "./store/ShoppingCart";
/*import Statement from "./store/Statement"; Note:this has been commented because statement is commented out*/
import ScrollToTop from "./ScrollToTop";
//import { BsLayerBackward } from "react-icons/bs";

function App() {
  //const [fullPageImg, setFullPg] = useState(false);
  const [toggler, setToggler] = useState(false);
  //const [displayToggle, setDisplayToggle] = useState(true);
  //const [linkColor, setLinkColor] = useState(props.colorChg);
  const styles = {
    // this styling is for the shopping cart
    cntnr: {
      fontSize: "1.8rem",
      display: "none",
      cursor: "pointer",
    },
    cart: {
      marginRight: "10px",
      marginLeft: "10px",
      marginTop: "20px",
    },
  };
  const toggle = () => {
    setToggler((toggler) => !toggler);
  };

  /*const navDisplay = () => {
	  setDisplayToggle(displayToggle => !displayToggle);
	  console.log(displayToggle);
	};*/
  //className="fa fa-shopping-cart fa-2x cartImg"

  //					<Route exact path="/login" component={Login} />
  /*<li onClick={toggle}>
							  <NavLink activeClassName="navbar-selected" className="navbar-link" exact to="/market">Market</NavLink>
						  </li> */
  //					<Route exact path="/market" component={Market} />

  //						<h3 className="navbar-title">London Heritage Farm</h3>
  // shop: style={{ display: displayToggle ? 'none' : 'none' }}
  /*<li onClick={toggle} style={{ display: displayToggle ? 'none' : 'none' }} >
								  <NavLink activeClassName="navbar-selected" className="navbar-link" exact to="/login">Sign In</NavLink>
							  </li>*/
  /* <Route
							  exact path='/login'
							  render={(props) => (
								  <Login {...props} displayInNav={navDisplay} />
							  )}
						  />*/

  //								<NavLink to="/cart" >  <i className="fa fa-shopping-cart fa-3x cartImg" ></i></NavLink>
  /*<li onClick={toggle}>
								  <NavLink to="/"
								  className={({ isActive }) => (isActive ? " active" : "inactive")} >Home</NavLink>
							  </li>*/

  return (
    <div>
      <div>
        <ScrollToTop />
      </div>
      <BrowserRouter>
        <nav className="Navbar">
          <div className="navbar-logo">
            <img src={logo} alt="London Farm" />
          </div>
          {/*<div className="navbar-title">
						<p>London Heritage Farm</p>
	</div>*/}

          <button className="navbarMenuBtn toggle" onClick={toggle}>
            <i className={"fa fa-bars fa-2x"} style={{ color: "black" }} />
          </button>

          <div className="navbarLinks">
            <ul className={"navbar-links " + (toggler ? "opened" : "closed")}>
              <li onClick={toggle}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-link" : "navbar-link"
                  }
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li onClick={toggle}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-link" : "navbar-link"
                  }
                  to="/explore"
                >
                  Explore
                </NavLink>
              </li>
              <li onClick={toggle}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-link" : "navbar-link"
                  }
                  to="/market"
                >
                  Gift Shop
                </NavLink>
              </li>

              <li onClick={toggle}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-link" : "navbar-link"
                  }
                  to="/events"
                >
                  Events
                </NavLink>
              </li>
              <li onClick={toggle}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-link" : "navbar-link"
                  }
                  to="/joinus"
                >
                  Join Us
                </NavLink>
              </li>

              <li onClick={toggle}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-link" : "navbar-link"
                  }
                  to="/gallery"
                >
                  Gallery
                </NavLink>
              </li>

              <li onClick={toggle}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-link" : "navbar-link"
                  }
                  to="/support"
                >
                  Plan Your Visit
                </NavLink>
              </li>

              {/*<li onClick={toggle} style={{ display: 'none' }} >
								<NavLink
									className={(navData) => (navData.isActive ? 'active-link' : 'navbar-link')}
									to="/statement">Statement
								</NavLink>
							</li> */}

              <li onClick={toggle}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-link" : "navbar-link"
                  }
                  to="/Weddings"
                >
                  Weddings etc
                </NavLink>
              </li>

              <li onClick={toggle} style={{ display: "none" }}>
                {/*the isActive will have to be modified IF a custom link is required*/}
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-link" : "navbar-link"
                  }
                  to="/donations"
                >
                  DONATIONS
                </NavLink>
              </li>
              {/*the isActive will have to be modified -
							make the style a new css style which will be referenced*/}

              <li onClick={toggle}>
                <NavLink style={styles.cntnr} to="/cart">
                  <BsCartFill />
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/market" element={<StoreCntnr />} />
          <Route path="/events" element={<Events />} />
          <Route path="/joinus" element={<JoinUs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/support" element={<Support />} />
          {/*<Route
						path='/members'
						render={(props) => (
							<Members {...props} displayInNav={navDisplay} />
						)}
						/> */}
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/weddings" element={<Weddings />} />

          <Route path="/donations" element={<Donations />} />

          {/* <Route path='/statement' element={<Statement />} />*/}

          <Route path="/cart" element={<ShoppingCart />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
