import React, { useState } from "react";
import Newsletter from "./Newsletter";
import NwsLtrSbscrDone from "./NwsLtrSbscrDone";

function NwsltrCntrlr(props) {
  const [formEnd, setFormEnd] = useState(false);
  const [formEndMsg, setFormEndMsg] = useState();
  //console.log(props.msg);

  const subscrbEndForm = (msg) => {
    setFormEnd(true);
    setFormEndMsg(msg);
    //console.log(formEnd);
    console.log(formEndMsg);
  };

  return (
    <div>
      <div className="sbscrHdrFlx">
        <div className="subscrbHdr">
          <i>Subscribe to our Newsletter</i>
        </div>
      </div>
      <div>
        {formEnd ? (
          <NwsLtrSbscrDone {...props} endMsg={formEndMsg} />
        ) : (
          <Newsletter {...props} subscrbEndForm={subscrbEndForm} />
        )}
      </div>
    </div>
  );
}
export default NwsltrCntrlr;