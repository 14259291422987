import React from "react";
import {
  GoogleMap,
  useLoadScript,
  //Marker,
  //InfoWindow,
} from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100vw",
  height: "90vh",

};
const center = {
  lat: 49.11465,
  lng: -123.15199,
};

export default function App() {
  //youtube: https://www.youtube.com/watch?v=WZcxJGmLbSo&t=0s
  //put key in .env and add libraries if needed
  //if places are needed, need to add libraries for that
  //add an h1? with london farm logo (@12 minutes)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAmLiMQA1LBPIR0nb8vK29w0zEOcV1GX0M"
  });

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";
  return <div className="i-top">
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={17}
      center={center}
    ></GoogleMap>
  </div>;
}