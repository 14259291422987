import React from "react";
import {useForm} from "react-hook-form";
import "./newsletter.css";
//import { Card, Input, Button, Error } from '../AuthForm';

function Newsletter(props) {
  const { register, handleSubmit, formState:{ errors } } = useForm();
  const onSubmit = data => {
    //const host = (process.env.REACT_APP_EXPRESS_URL);
    // host = the http(s) + the host + the port(localhost only (dev))
    // the host is determined by the start/build scripts in package.json

    const host = process.env.REACT_APP_EXPRESS_LHF;
    const expressUrl = host + '/routes/newSubscriberNoVrf';

    console.log(expressUrl);
    fetch(expressUrl, {
      method: 'POST',
      //mode: 'no-cors',
      body: JSON.stringify(data),
      redirect: 'follow',
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    })
      .then((res) => {
        console.log(res.status);
        //console.log(res.error);
        let msg;
        if (res.status === 300) {
          //this is a duplicate email addr
          msg = "You are already subscribed - Have a great day:)";
        } else {
          msg = "Thanks for Subscribing to The London Heritage Farm Society Newsletter";
        }
        props.subscrbEndForm(msg);
        console.log(props.subscrbEndForm(msg));
      });
  };
  return (
    <div>
      <form className="subscribe-form" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="name"
          {...register("name",{
            required:true,
            validate: value => value !== "admin" || "Nice try!"
          })}
          placeholder="Your First Name"
        />
        {errors.name && <p className="error"> {errors.name.message}</p>}
        <input
          type="email"
          {...register("email",{
            required:true,
            pattern: {
              value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message:"Should be a valid email",
            },
          })}
          placeholder="email"
        />
        {errors.email &&  <p className="error">{errors.email.message}
        </p>}
        <button type="submit">SUBSCRIBE</button>
      </form>
    </div>
  );
}
export default Newsletter;