import React from "react";
import ReactPlayer from 'react-player';
//import "../index.css";
import "./multiCar.css";

function MCHome4 () {

  return (
    <div className="mcVidCntnr" style={{
      background:'black'

    }}
    >
      <div className="mcVidSet i-top">
        <div>
          <p className="mcVidTitle">Family Farm Day 2022</p>
        </div>
        <div className="player-wrapper">

          <ReactPlayer
            className="react-player"
            url="https://youtu.be/053XT6qkP14"
            width='100%'
            height='100%'
            controls={true}
          />
        </div>
      </div>
    </div>

  );
}

export default MCHome4;
